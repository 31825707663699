var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs"},[_c('el-dialog',{attrs:{"title":"调整入住方案","visible":_vm.dialogShow,"width":"800px","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogShow=$event}}},[_c('div',{staticClass:"center"},[_c('div',{staticClass:"center-top"},[_c('div',{staticClass:"item"},[_c('span',{staticClass:"item-top"},[_vm._v("房间1")]),_c('draggable',{staticClass:"item-bottom",attrs:{"ghostClass":"ghost","group":{
							name: 'people',
							put: _vm.arr1.length >= 2 ? false : true,
						},"animation":"300","sort":false},on:{"start":_vm.onStart,"end":_vm.onEnd},model:{value:(_vm.arr1),callback:function ($$v) {_vm.arr1=$$v},expression:"arr1"}},_vm._l((_vm.arr1),function(item){return _c('div',{key:item.empId,staticClass:"item-person"},[_vm._v(" "+_vm._s(item.name || '')+" ")])}),0)],1),_c('div',{staticClass:"item"},[_c('span',{staticClass:"item-top"},[_vm._v("房间2")]),_c('span',{staticClass:"item-bottom"},[_c('draggable',{staticStyle:{"width":"100%","min-height":"100%"},attrs:{"group":{
								name: 'people',
								put: _vm.arr2.length >= 2 ? false : true,
							},"animation":"300","sort":false},on:{"start":_vm.onStart,"end":_vm.onEnd},model:{value:(_vm.arr2),callback:function ($$v) {_vm.arr2=$$v},expression:"arr2"}},_vm._l((_vm.arr2),function(item){return _c('div',{key:item.empId,staticClass:"item-person"},[_vm._v(" "+_vm._s(item.name || '')+" ")])}),0)],1)]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"item-top"},[_vm._v("房间3")]),_c('span',{staticClass:"item-bottom"},[_c('draggable',{staticStyle:{"width":"100%","min-height":"100%"},attrs:{"group":{
								name: 'people',
								put: _vm.arr3.length >= 2 ? false : true,
							},"animation":"300","sort":false},on:{"start":_vm.onStart,"end":_vm.onEnd},model:{value:(_vm.arr3),callback:function ($$v) {_vm.arr3=$$v},expression:"arr3"}},_vm._l((_vm.arr3),function(item){return _c('div',{key:item.empId,staticClass:"item-person"},[_vm._v(" "+_vm._s(item.name || '')+" ")])}),0)],1)]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"item-top"},[_vm._v("房间4")]),_c('span',{staticClass:"item-bottom"},[_c('draggable',{staticStyle:{"width":"100%","min-height":"100%"},attrs:{"group":{
								name: 'people',
								put: _vm.arr4.length >= 2 ? false : true,
							},"animation":"300","sort":false},on:{"start":_vm.onStart,"end":_vm.onEnd},model:{value:(_vm.arr4),callback:function ($$v) {_vm.arr4=$$v},expression:"arr4"}},_vm._l((_vm.arr4),function(item){return _c('div',{key:item.empId,staticClass:"item-person"},[_vm._v(" "+_vm._s(item.name || '')+" ")])}),0)],1)]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"item-top"},[_vm._v("房间5")]),_c('span',{staticClass:"item-bottom"},[_c('draggable',{staticStyle:{"width":"100%","min-height":"100%"},attrs:{"group":{
								name: 'people',
								put: _vm.arr5.length >= 2 ? false : true,
							},"animation":"300","sort":false},on:{"start":_vm.onStart,"end":_vm.onEnd},model:{value:(_vm.arr5),callback:function ($$v) {_vm.arr5=$$v},expression:"arr5"}},_vm._l((_vm.arr5),function(item){return _c('div',{key:item.empId,staticClass:"item-person"},[_vm._v(" "+_vm._s(item.name || '')+" ")])}),0)],1)])]),_c('span',{staticClass:"center-span"},[_vm._v("拖动入住人到房间，实现调整入住方案，每个房间最多容纳2人")]),_c('div',{staticClass:"center-bottom"},[_c('div',{staticClass:"item-top"},[_vm._v(" 移除人员列表 (将入住人拖入下框中，表示从入住人中删除 ")]),_c('draggable',{staticStyle:{"width":"758px","min-height":"100%","display":"flex"},attrs:{"group":{ name: 'people' },"animation":"300","sort":false},on:{"start":_vm.onStart,"end":_vm.onEnd},model:{value:(_vm.arr6),callback:function ($$v) {_vm.arr6=$$v},expression:"arr6"}},_vm._l((_vm.arr6),function(item){return _c('span',{key:item.empId,staticClass:"bottom-person"},[_vm._v(_vm._s(item.name))])}),0)],1)]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handlerQuery}},[_vm._v("确 定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }