<template>
	<div class="tabs">
		<el-dialog
			title="调整入住方案"
			:visible.sync="dialogShow"
			width="800px"
			:before-close="handleClose"
		>
			<div class="center">
				<div class="center-top">
					<div class="item">
						<span class="item-top">房间1</span>
						<draggable
							v-model="arr1"
							class="item-bottom"
							ghostClass="ghost"
							:group="{
								name: 'people',
								put: arr1.length >= 2 ? false : true,
							}"
							animation="300"
							@start="onStart"
							:sort="false"
							@end="onEnd"
						>
							<div
								v-for="item in arr1"
								class="item-person"
								:key="item.empId"
							>
								{{ item.name || '' }}
							</div>
						</draggable>
					</div>
					<div class="item">
						<span class="item-top">房间2</span>
						<span class="item-bottom">
							<draggable
								v-model="arr2"
								style="width: 100%; min-height: 100%"
								:group="{
									name: 'people',
									put: arr2.length >= 2 ? false : true,
								}"
								animation="300"
								@start="onStart"
								:sort="false"
								@end="onEnd"
							>
								<div
									v-for="item in arr2"
									class="item-person"
									:key="item.empId"
								>
									{{ item.name || '' }}
								</div>
							</draggable>
						</span>
					</div>
					<div class="item">
						<span class="item-top">房间3</span>
						<span class="item-bottom">
							<draggable
								v-model="arr3"
								style="width: 100%; min-height: 100%"
								:group="{
									name: 'people',
									put: arr3.length >= 2 ? false : true,
								}"
								animation="300"
								@start="onStart"
								:sort="false"
								@end="onEnd"
							>
								<div
									v-for="item in arr3"
									class="item-person"
									:key="item.empId"
								>
									{{ item.name || '' }}
								</div>
							</draggable>
						</span>
					</div>
					<div class="item">
						<span class="item-top">房间4</span>
						<span class="item-bottom">
							<draggable
								v-model="arr4"
								style="width: 100%; min-height: 100%"
								:group="{
									name: 'people',
									put: arr4.length >= 2 ? false : true,
								}"
								animation="300"
								@start="onStart"
								:sort="false"
								@end="onEnd"
							>
								<div
									v-for="item in arr4"
									class="item-person"
									:key="item.empId"
								>
									{{ item.name || '' }}
								</div>
							</draggable>
						</span>
					</div>
					<div class="item">
						<span class="item-top">房间5</span>
						<span class="item-bottom">
							<draggable
								v-model="arr5"
								style="width: 100%; min-height: 100%"
								:group="{
									name: 'people',
									put: arr5.length >= 2 ? false : true,
								}"
								animation="300"
								@start="onStart"
								:sort="false"
								@end="onEnd"
							>
								<div
									v-for="item in arr5"
									class="item-person"
									:key="item.empId"
								>
									{{ item.name || '' }}
								</div>
							</draggable>
						</span>
					</div>
				</div>
				<span class="center-span"
					>拖动入住人到房间，实现调整入住方案，每个房间最多容纳2人</span
				>
				<div class="center-bottom">
					<div class="item-top">
						移除人员列表 (将入住人拖入下框中，表示从入住人中删除
					</div>
					<draggable
						v-model="arr6"
						style="width: 758px; min-height: 100%; display: flex"
						:group="{ name: 'people' }"
						animation="300"
						@start="onStart"
						:sort="false"
						@end="onEnd"
					>
						<span
							v-for="item in arr6"
							class="bottom-person"
							:key="item.empId"
							>{{ item.name }}</span
						>
					</draggable>
				</div>
			</div>

			<span slot="footer" class="dialog-footer">
				<el-button @click="handleClose">取 消</el-button>
				<el-button type="primary" @click="handlerQuery"
					>确 定</el-button
				>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
	components: { draggable },

	// 首先，房间必须是5个，不然调整就没有意义了
	// 返回的给的数据 当不存在的时候是对象是为空的，先遍历一遍

	props: {
		dialogShow: {
			type: Boolean,
		},
	},
	computed: {
		...mapState({
			cusRoomsState: (state) => state.htCn.cusRoomsState,
		}),
		romms() {
			return this.cusRoomsState
		},
	},
	watch: {
		dialogShow() {
			// 开始遍历cusRoomsState！把每个房间的customers，分配给不同房间！然后再集中处理数据！，确定的时候再赋值给cusRoomsState。形成一个完美的闭环！
			// 做一遍去空处理方便遍历

			this.romms.map((item) => {
				item.customers.map((e, index) => {
					if (e.empDeptId == '' && e.empId == '') {
						item.customers.splice(index, 1)
					}
				})
			})
			this.arr1 = this.romms[0]?.customers ?? []
			this.arr2 = this.romms[1]?.customers ?? []
			this.arr3 = this.romms[2]?.customers ?? []
			this.arr4 = this.romms[3]?.customers ?? []
			this.arr5 = this.romms[4]?.customers ?? []
		},
	},
	data() {
		return {
			drag: false,
			tabPosition: 'left',
			isShow: 1,
			arr1: [],
			arr2: [],
			arr3: [],
			arr4: [],
			arr5: [],
			arr6: [],
		}
	},
	mounted() {},
	methods: {
		...mapMutations({
			COPY_ROOMS: 'htCn/COPY_ROOMS',
		}),
		onStart() {
			this.drag = true
		},
		//拖拽结束事件
		onEnd() {
			this.drag = false
		},
		handleTab(val) {
			this.isShow = val
		},
		handleClose() {
			this.arr6 = [];
			this.$emit('update:dialogShow', false)
		},
		handlerQuery() {
			// 点击确定重排数据 处理好数据在赋值给vuex里面
			let arr = []
			let newArr = []
			arr = [
				[...this.arr1],
				[...this.arr2],
				[...this.arr3],
				[...this.arr4],
				[...this.arr5],
			]
			// 入住人分配房间数组
			let personList = [];
			arr.forEach((item, index) => {
				let obj = {
						customers: '',
						rooms: '',
					}
				if (item.length==0) {
				}else{
					obj.customers = item
					obj.rooms = index
					personList.push(obj)
				}
			}) 
			// 处理列表中数组长度为一的列表，保证每个都有两项数据
			personList.map((item,index)=>{
				let customersObj = {
					empDeptId: '',
					empId: '',
					name: '',
				}	
				if(item.customers.length == 1) {
					item.customers.push(customersObj)
				}
				
			})
			// 去空处理 //得删除两次，不然会有残留 (此处不能arr.splice，原数组会变化)
			// arr.map((item, index) => {
			// 	if (item.length == 0) {
			// 		arr.splice(index, 1)
			// 	}
			// })
			// arr.forEach((item, index) => {
			// 	if (item.length == 0) {
			// 		arr.splice(index, 1)
			// 	}
			// 	let obj = {
			// 		customers: '',
			// 		rooms: '',
			// 	}
			// 	obj.customers = item
			// 	obj.rooms = index
				
			// 	newArr = newArr.concat([obj])
			// })

			// newArr.forEach((item, index) => {
			// 	let customersObj = {
			// 		empDeptId: '',
			// 		empId: '',
			// 		name: '',
			// 	}
			// 	// 当房间里面只有一项的时候，建个对象传进去，保证每个里面有两项数据
			// 	if (item.customers.length < 2 && item.customers.length!=0) {
			// 		item.customers.push(customersObj)
			// 	}else if(item.customers.length==0){
			// 		//此处处理当item.customers 为空时清除对象
			// 		newArr.splice(index, 1)
			// 	}
			// })
			//存入vuex 暴露方法给父组件
			this.COPY_ROOMS(personList)
			this.$emit('handlerQuery',personList)
			this.$emit('update:dialogShow', false)
		},
	},
}
</script>

<style lang="scss" scoped="scoped">
// /deep/ .el-dialog__header {
//   padding: 0;
// }
// /deep/ .el-dialog__body{
//     padding: 0;
// }
.bottom-person {
	width: 80px;
	height: 40px;
	border: 1px solid #ddd;
	margin: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.ghost {
	background-color: red !important;
}
/deep/ .el-dialog__footer {
	text-align: center;
}
.center {
	display: flex;
	flex-direction: column;
	.center-top {
		display: flex;
		justify-content: space-around;
		.item {
			width: 113px;
			//   height: 158px;
			border: 1px solid #0982df;
			margin: 10px 0;
			margin-right: 32px;
			.item-top {
				border-bottom: 1px solid #0982df;
				background-color: #eff8ff;
				height: 24px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.item-bottom {
				height: 100px;
				flex: 1;
				.item-person {
					width: 80px;
					height: 30px;
					margin: 0 auto;
					border: 1px solid #ddd;
					margin-top: 5px;
					margin-bottom: 5px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
		.item:last-child {
			margin-right: 0px;
		}
	}
	.center-span {
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.center-bottom {
		border: 1px solid #0982df;
		width: 100%;
		height: 120px;
		.item-top {
			border-bottom: 1px solid #0982df;
			background-color: #eff8ff;
			height: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
			.bottom-drag {
			}
		}
	}
}
</style>
